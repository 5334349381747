<template>
  <section :id="'timeline-section-' + pagePosition" ref="section" :data-index="pagePosition">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-6 order-1" :class="item.side !== 'right' ? 'order-md-0' : 'order-md-1'">
          <Observer name="fade-up">
            <h2 class="title" v-if="item.title" v-html="item.title"></h2>
            <p class="text" v-if="item.text" v-html="item.text"></p>
            <router-link v-if="item.ctaText && item.ctaLink" :to="localizedUrl + item.ctaLink" class="link-light">{{ item.ctaText }}</router-link>
            <!-- router-link v-if="item.ctaText && item.ctaLink" :to="localizedUrl + item.ctaLink" class="btn -stroke">{{ item.ctaText }}</router-link -->
            <div class="movements">
              <hooper v-if="items.length > 1" :settings="movementsSettings" ref="movements" :class="'-white'" @slide="updateProgressMovement" @updated="updateSliderMovement" tabindex="-1">
                <slide ref="slides" v-for="(movement, i) in items" :key="movement.title + '_' + i">
                  <button class="movement" type="button" @click="selectedMovement = i" :class="{'-active': selectedMovement === i}">
                    <lazy-picture :item="movement" desktop-ratio="1" mobile-ratio="1"></lazy-picture>
                    <span class="movement-title" v-if="movement.title" v-html="movement.title"></span>
                    <span v-if="movement.ctaText && movement.ctaLink" :to="localizedUrl + movement.ctaLink" class="link-light">{{ movement.ctaText }}</span>
                  </button>
                </slide>
              </hooper>
              <div class="movements-navigation" v-if="items.length > 3">
                <button ref="prevButton" type="button" class="hooper-prev" :class="{'is-disabled': currentSlideMovement <= 1 }" :aria-label="$t('Previous slide')" @click="() => $refs.movements.slidePrev()"></button>
                <button ref="nextButton" type="button" class="hooper-next" :class="{'is-disabled': currentSlideMovement >= items.length - 2}" :aria-label="$t('Next slide')" @click="() => $refs.movements.slideNext()"></button>
              </div>
            </div>
          </Observer>
        </div>
        <div class="col-12 col-md-6 order-0" :class="item.side !== 'right' ? 'order-md-1' : 'order-md-0'">
          <Observer name="fade-up">
            <div class="visual">
              <lazy-picture :item="item" desktop-ratio="1.44827586" mobile-ratio="1.58823529"></lazy-picture>
              <button type="button" class="btn-play" @click="playVideo = true" v-if="item.video">
                <span>{{ $t('Play') }}</span>
              </button>
            </div>
          </Observer>
        </div>
      </div>
      <transition name="fade-up">
        <div class="row justify-content-center products" v-if="items[selectedMovement] && items[selectedMovement].products.length">
          <div class="col-12 col-md-3">
            <div class="movement">
              <div>
                <lazy-picture :item="items[selectedMovement]" desktop-ratio="1" mobile-ratio="1"></lazy-picture>
                <strong class="title" v-if="items[selectedMovement].title" v-html="items[selectedMovement].title"></strong>
                <span class="text" v-if="items[selectedMovement].text" v-html="items[selectedMovement].text"></span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9">
            <hooper :settings="productsSettings" :class="{'-white' : true, '-small': items[selectedMovement].products.length < productsToShow}" ref="products" @slide="updateProgressProduct" @updated="updateSliderProduct" tabindex="-1">
              <slide v-for="(product, i) in items[selectedMovement].products" :key="product.sku" :index="i">
                <product-item :product="product" :class-obs="'delay-' + i%productsToShow" :list-position="i" :list-name="item.title ? item.title : $t('See all the collection')" :list-type="'Page ' + $route.name.substring(6).replace('-', ' ')"></product-item>
              </slide>
              <div class="hooper-navigation container" slot="hooper-addons" v-if="items[selectedMovement].products.length > productsToShow">
                <button type="button" class="hooper-prev" :class="{'is-disabled': !currentSlideProduct}" :aria-label="$t('Previous slide')" @click="() => $refs.products.slidePrev()"></button>
                <button type="button" class="hooper-next" :class="{'is-disabled': currentSlideProduct >= items[selectedMovement].products.length - productsToShow}" :aria-label="$t('Next slide')" @click="() => $refs.products.slideNext()"></button>
              </div>
              <hooper-progress v-if="items.length > 1" slot="hooper-addons"></hooper-progress>
            </hooper>
          </div>
        </div>
      </transition>
    </div>
    <VideoPlayerFS :play-video="playVideo" :item="item" @closeVideo="() => { playVideo = false }" v-if="item.video"></VideoPlayerFS>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture'
import VideoPlayerFS from './VideoPlayerFS'
import ProductItem from './ProductItem'
import { Hooper, Slide, Progress as HooperProgress } from 'hooper'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components', 'declination'],
  name: 'MovementSelector',
  mixins: [ReadyHook],
  components: {
    Hooper,
    Slide,
    LazyPicture,
    VideoPlayerFS,
    ProductItem,
    HooperProgress
  },
  data () {
    return {
      playVideo: false,
      selectedMovement: null,
      currentSlideMovement: 0,
      currentSlideProduct: 0,
      movementsToShow: 0,
      productsToShow: 0,
      movementsSettings: {
        initialSlide: 0,
        itemsToShow: 2,
        transition: 400,
        autoPlay: false,
        mouseDrag: true,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        centerMode: true,
        breakpoints: {
          768: {
            centerMode: true,
            itemsToShow: 3,
            initialSlide: 0
          }
        }
      },
      productsSettings: {
        initialSlide: 0,
        itemsToShow: 1.25,
        transition: 400,
        autoPlay: false,
        mouseDrag: true,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        centerMode: false,
        breakpoints: {
          768: {
            initialSlide: 0,
            itemsToShow: 3
          }
        }
      }
    }
  },
  watch: {
    selectedMovement () {
      this.$refs.movements.slideTo(this.selectedMovement)
    }
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    }
  },
  methods: {
    updateSliderMovement (payload) {
      this.movementsToShow = payload.settings.itemsToShow
    },
    updateProgressMovement (payload) {
      this.currentSlideMovement = payload.currentSlide
    },
    updateSliderProduct (payload) {
      this.productsToShow = payload.settings.itemsToShow
    },
    updateProgressProduct (payload) {
      this.currentSlideProduct = payload.currentSlide
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

section {
  position: relative;
  margin: 4rem 0;
}

.title {
  font-weight: 400;
  font-size: em(38);
  line-height: em(48, 38);
  margin-bottom: 2.4rem;
  color: var(--zenith-theme-primary);

  @include bp(max md) {
    font-size: em(26);
    line-height: em(30, 26);
  }
}

.text {
  font-size: em(16);
  letter-spacing: 0;
  line-height: em(28, 16);
  color: var(--zenith-grey);
  margin-bottom: 2.4rem;
}

a {
  margin-bottom: 4rem;
}

.visual {
  position: relative;

  @include bp(max md) {
    margin-bottom: 2.4rem;
  }

  .btn-play {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ img {
    max-width: 100%;
  }
}

.movements {
  position: relative;
  margin: 2rem 0 0;
  padding: 0;
  width: calc(100% - 2rem);

  @include bp(max md) {
    width: 100vw;
    left: -1.5rem;
  }

  .hooper {
    margin: 0;
    height: auto;

    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }

  &-navigation {
    position: absolute;
    top: 0;
    left: -4rem;
    width: calc(100% + 8rem);
    height: 100%;
    pointer-events: none;

    @include bp(max md) {
      width: 100%;
      left: 0;
      display: none;
    }

    .hooper-prev {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      height: 80%;
      width: 4rem;
      padding: 0;
      transform: none;
      pointer-events: all;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.4rem;
        height: 1.4rem;
        border-left: 1px solid var(--zenith-theme-primary);
        border-top: 1px solid var(--zenith-theme-primary);
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: all 0.2s ease-in-out;

        @include bp(max md) {
          width: 1rem;
          height: 1rem;
        }
      }

      &.is-disabled {
        opacity : 0;
        pointer-events: none;
      }

      &:hover, &:focus {
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg) scale(1.2);
        }
      }
    }

    .hooper-next {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      height: 80%;
      width: 4rem;
      padding: 0;
      transform: none;
      pointer-events: all;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.4rem;
        height: 1.4rem;
        border-right: 1px solid var(--zenith-theme-primary);
        border-bottom: 1px solid var(--zenith-theme-primary);
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: all 0.2s ease-in-out;

        @include bp(max md) {
          width: 1rem;
          height: 1rem;
        }
      }

      &.is-disabled {
        opacity : 0;
        pointer-events: none;
      }

      &:hover, &:focus {
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg) scale(1.2);
        }
      }
    }
  }
}

.movement {
  position: relative;
  width: 100%;
  border: none;
  background: none;
  padding: 0 0 5rem;
  text-align: center;
  outline: none;
  transition: all 0.2s ease-in-out;

  &-title {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, -1rem);
    display: block;
    color: var(--zenith-grey);
    font-size: em(14);
    line-height: em(20, 14);
    margin-bottom: .4rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
  }

  .link-light {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -1rem);
    margin-right: 0;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
  }

  /deep/ img {
    width: 100%;
    transform: scale(0.6);
    transition: all 0.2s ease-in-out;
  }

  &:hover, &:focus, &.-active {
    outline: none;

    .movement-title {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    .link-light {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &.-active {
    /deep/ img {
      transform: scale(1);
    }
  }
}

.products {
  padding-top: 2.5rem;

  .movement {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;

    /deep/ img {
      width: 100%;
      transform: none;
    }

    .title {
      display: block;
      font-weight: 500;
      font-size: em(16);
      letter-spacing: em(1.5, 16);
      margin-bottom: 0.5rem;
      margin-top: 2rem;
    }

    .text {
      display: block;
      font-weight: 500;
      font-size: em(14);
      line-height: em(22, 14);
      color: var(--zenith-grey);
    }

  }

  .hooper {
    margin: 0 -1.5rem;
    padding: 0;
    height: auto;
    outline: none;
    width: calc(100% + 3rem);

    &.-small {
      font-size: em(14);

      /deep/ .hooper-track {
        transform: none !important
      }

      /deep/ .hooper-liveregion.hooper-sr-only {
        display: none;
      }
    }

    /deep/ .hooper-slide {
      position: relative;
      outline: none;
      height: auto;
      padding: 1.5rem;

      /deep/ .obs {
        height: 100%;
      }

      /deep/ > .obs {
        transform: translateY(0);

        &.obs-fade-up {
          transform: translateY(10rem);
        }
      }
    }
  }

  .product {
    position: relative;
    // background: var(--zenith-product-item-background);
    padding: 2rem 2rem 3rem;
    overflow: hidden;
    height: 100%;

    .link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 20;
    }

    @include bp(max md) {
      padding: 1rem;
    }

    .fav {
      position: absolute;
      top: 4rem;
      right: 2rem;
      z-index: 30;
      background: none;
      border: none;
      padding: 0;
      margin: 0;

      &:hover, &:focus, &:active {
        transform: scale(1.1);
      }

      i {
        display: block;
      }

      @include bp(max md) {
        top: 1.5rem;
        right: 1.5rem;
      }
    }

    span {
      display: block;
      margin-bottom: 1rem;
      text-transform: uppercase;
      transition: all 0.4s ease-in-out;
      pointer-events: none;
    }

    .watch {
      max-width: 100%;
      margin-bottom: 2rem;
      transition: all 2s cubic-bezier(.075, .82, .165, 1);
    }

    .cover {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 20;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
    }

    .btn {
      position: absolute;
      z-index: 30;
      bottom: 4rem;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      transform: translate(-50%, 1rem);

      @include bp(max md) {
        bottom: 2rem;
      }
    }

    &:hover,
    &:focus,
    &:active {
      span {
        opacity: 0;
      }

      .watch {
        transform: scale(1.2);
      }

      .cover {
        opacity: 1;
        transform: scale(1);
      }

      .btn {
        opacity: 1;
        pointer-events: all;
        transform: translate(-50%, 0);
      }

      .fav {
        color: var(--zenith-white);
      }
    }
  }

  /deep/ .hooper-navigation {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    @include bp(max md) {
      display: none;
    }

    .hooper-prev {
      left: -1.5rem;
      outline: none;

      &:before {
        content: "\e903";
      }

      &:hover {
        &:before {
          @include pillPrevHover();
        }
      }
    }

    .hooper-next {
      right: -1.5rem;
      outline: none;

      &:before {
        content: "\e925";
      }

      &:hover {
        &:before {
          @include pillNextHover();
        }
      }
    }

    button {
      opacity: 0.5;
      padding: 1.3rem;
      border-radius: 100%;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
      background: #f8f8f8;

      svg {
        display: none;
      }

      &:before {
        display: block;
        font-family: 'zenith', sans-serif !important;
        speak: none;
        font-size: em(20);
        line-height: 2rem;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.is-disabled {
        opacity: 0 !important;
        pointer-events: none;
      }

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }

  /deep/ .hooper-progress {
    position: relative;
    background: #cccccc;
    height: .2rem;
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;

    @include bp(md) {
      display: none;
    }

    &-inner {
      background: #000;
    }
  }
}
</style>
